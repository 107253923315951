import { shallowRef } from 'vue'
import { useRouter } from 'vue-router/composables'
import { Payrails } from '@payrails/web-sdk'
import '@payrails/web-sdk/payrails-styles.css'
import store from '@/app/store'
import { emitter } from '@/app/system/plugins/mitt'
import { isDevMode } from '../system/helper'

// Global instance that persists across components
const globalPayrailsInstance = shallowRef<any>(null)

export function usePayrails() {
  const router = useRouter()

  const initPayrailsPayment = (initToken: string, initVersion: string) => {
    const config = {
      data: initToken,
      version: initVersion
    }

    const options = {
      environment: isDevMode() ? 'TEST' : 'PROD',
      events: {
        onClientInitialized: (execution: any) => {
          emitter.emit('payrails-client-init', true)
        }
      }
    }

    globalPayrailsInstance.value = Payrails.init(config, options)

    const dropinConfig = {
      paymentMethodsConfiguration : {
        preselectFirstPaymentOption: true,
        cards: {
          showPaymentMethodLogo: true,
        },
        googlePay : {
          environment: 'TEST',
          merchantInfo: {
            merchantId: 'BCR2DN4T765PHEC5',
            merchantName: 'Hotel-Spider',
          },
          styles: {
            buttonType: 'book',
            buttonColor: 'white', // could be 'black' when dark mode is enabled
          },
          showPaymentMethodLogo: true,
        },
      },
      translations: {
        cardPaymentButton: {
          label: ''
        },
        cardForm: {
          labels: {
            label: ''
          }
        },
        paymentResult: {
          success: 'optional',
          fail: 'optional',
          pending: 'optional'
        },
        mercadoPago: {
          translations: {
            label: '',
            button: {
              label: ''
            }
          }
        },
        addressSelector: {
          labels: {
            countrySelector: '',
            postalCodeInput: ''
          },
          placeholders: {
            postalCodeInput: '',
            countrySelector: ''
          }
        }
      },
      styles: {
        container: {} as Partial<CSSStyleDeclaration>,
        element: {
          base: {} as Partial<CSSStyleDeclaration>,
          active: {} as Partial<CSSStyleDeclaration>
        },
        cardForm: {
          wrapper: {} as Partial<CSSStyleDeclaration>,
          base: {} as Partial<CSSStyleDeclaration>,
          storeInstrumentCheckbox: {} as Partial<CSSStyleDeclaration>,
          errorTextStyles: {
            base: {} as Partial<CSSStyleDeclaration>
          },
          // declare enum ElementType$1 {
          //     CVV = "CVV",
          //     EXPIRATION_DATE = "EXPIRATION_DATE",
          //     CARD_NUMBER = "CARD_NUMBER",
          //     CARDHOLDER_NAME = "CARDHOLDER_NAME",
          //     INPUT_FIELD = "INPUT_FIELD",
          //     PIN = "PIN",
          //     EXPIRATION_MONTH = "EXPIRATION_MONTH",
          //     EXPIRATION_YEAR = "EXPIRATION_YEAR"
          // }
          inputFields: {
            // [key in ElementType | 'all']?: CardFieldStyles
          },
          labels: {
            // [key in ElementType | 'all']?: Partial<CSSStyleDeclaration>
          },
          paymentInstallmentsDropdown: {} as Partial<CSSStyleDeclaration>
        },
        cardPaymentButton: {
          base: {} as Partial<CSSStyleDeclaration>,
          disabled: {} as Partial<CSSStyleDeclaration>,
          loading: {} as Partial<CSSStyleDeclaration>,
          hover: {} as Partial<CSSStyleDeclaration>
        },
        authSuccess: {} as Partial<CSSStyleDeclaration>,
        authFailed: {} as Partial<CSSStyleDeclaration>,
        addressSelector: {
          wrapper: {} as Partial<CSSStyleDeclaration>,
          countrySelector: {
            wrapper: {} as Partial<CSSStyleDeclaration>,
            element: {} as Partial<CSSStyleDeclaration>,
            label: {} as Partial<CSSStyleDeclaration>
          },
          postalCodeInput: {
            wrapper: {} as Partial<CSSStyleDeclaration>,
            element: {} as Partial<CSSStyleDeclaration>,
            label: {} as Partial<CSSStyleDeclaration>
          }
        },
        mercadoPago: {
          // element?: DropinElementStyles
          // button: ButtonOptions['styles']
        }
      },
      events: {
        onAuthorizeSuccess: () => {
          router.push({
            name: 'PaymentCallback',
            query: {
              payrails_status: 'success',
              order_id: store.getters.orderInfo.reservationId
            }
          })
        },
        onAuthorizeFailed: (error) => {
          console.error('Payrails authorization failed: ', error)
          router.push({
            name: 'PaymentCallback',
            query: {
              payrails_status: 'fail',
              order_id: store.getters.orderInfo.reservationId,
              error_type: 'payment_failed'
            }
          })
        },
        onSessionExpired: () => {
          console.error('Payrails session expired')
          router.push({
            name: 'PaymentCallback',
            query: {
              payrails_status: 'fail',
              order_id: store.getters.orderInfo.reservationId,
              error_type: 'session_expired'
            }
          })
        }
      }
    }

    const dropin = globalPayrailsInstance.value.dropin(dropinConfig)
    dropin.mount('#payrails-payment-container')
  }

  const processPayrailsPayment = async (paymentData: InitiatePaymentResponsePayrails) => {
    try {
      if (paymentData?.data && paymentData.paymentProvider === 'payrails') {
        initPayrailsPayment(paymentData.data.data, paymentData.data.version)
      } else {
        throw new Error('Invalid Payrails payment data')
      }
    } catch (error) {
      console.error('Error processing Payrails payment:', error)
      throw error
    }
  }

  return {
    payrailsInstance: globalPayrailsInstance,
    processPayrailsPayment
  }
}
